import { useEffect, useRef, useState } from 'react';
import fm from 'format-message';
import PropTypes from 'prop-types';
import textStyles from 'config/branding/textStyles';
import Image from '@activebrands/core-web/components/Image';
import ScrollableBox from '@activebrands/core-web/components/ScrollableBox';
import withHeightAuto from '@activebrands/core-web/components/withHeightAuto';
import media from '@activebrands/core-web/config/media';
import useOnClickOutside from '@activebrands/core-web/hooks/useClickOutside';
import { styled, useStyletron } from '@activebrands/core-web/libs/styletron';
import InputErrors from 'components/Form/InputErrors';
import StateIcon from 'components/icons/StateIcon';
import { Label } from './Input';

const SelectMobile = styled('select', {
    opacity: 0,
    position: 'absolute',
    height: 'calc( 100% + 8px )',
    width: '100%',
    zIndex: 3,

    [media.min['tablet.lg']]: {
        display: 'none',
    },
});

const Wrapper = styled('div', {
    lineHeight: 1,
    paddingBottom: '8px',
});

const ToggleButton = styled('button', ({ $errors }) => ({
    position: 'relative',
    width: '100%',
    padding: '8px',
    margin: 0,
    borderRadius: '8px',
    border: $errors ? '1px solid var(--color-text-error)' : '1px solid var(--color-border-select-list)',
    backgroundColor: 'var(--color-bg-highlight)',
    color: 'var(--color-text)',
    textAlign: 'left',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    ...textStyles['Miscellaneous/16_100_-1'],
}));

const TextSpan = styled('span', {
    display: 'inline-block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
});

const ImageWrapper = styled('div', {
    display: 'inline-block',
    width: '16px',
    height: '16px',
    marginRight: '8px',
    borderRadius: '50%',
    overflow: 'hidden',
});

const Collapse = withHeightAuto(
    styled('div', {
        position: 'relative',
        zIndex: '2',
        marginTop: '-8px',
        overflow: 'hidden',
        transition: 'height var(--duration-primary) var(--ease)',
    })
);

const CollapseInner = styled('div', {
    padding: '12px',
    borderLeft: '1px solid var(--color-border-select-list)',
    borderRight: '1px solid var(--color-border-select-list)',
    borderBottom: '1px solid var(--color-border-select-list)',
    background: 'var(--color-bg-select-list)',
    borderRadius: '0px 0px 8px 8px',
});

const FilterInput = styled('input', {
    display: 'block',
    width: '100%',
    padding: '8px 0px',
    border: 0,
    color: 'var(--color-text)',
    background: 'none',
    ...textStyles['Miscellaneous/16_100_-1'],

    ':focus': {
        outline: 'none',
    },
});

const OptionWrapper = styled('li', ({ $selected, $hide, $icon }) => ({
    position: 'relative',
    display: $hide ? 'none' : 'block',
    width: '100%',

    '::before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        borderRadius: '50%',
        height: '14px',
        width: '14px',
        top: '6px',
        left: '0px',
        border: $icon && !$selected ? null : `1px solid var(--color-bg-select)`,
        backgroundImage: $icon && !$selected ? `url(${$icon})` : null,
        backgroundPosition: 'center center',
    },

    '::after': {
        content: '""',
        display: $selected ? 'block' : 'none',
        position: 'absolute',
        borderRadius: '50%',
        height: '8px',
        width: '8px',
        top: '9px',
        left: '3px',
        backgroundColor: 'var(--color-bg-select)',
    },
}));

const Option = styled('button', () => ({
    display: 'block',
    width: '100%',
    padding: '6px 4px 6px 24px',
    textAlign: 'left',
    cursor: 'pointer',

    ':focus': {
        outline: '0',
        background: 'rgba(0,0,0,0.05)',
    },

    ':hover': {
        outline: '0',
        background: 'rgba(0,0,0,0.05)',
    },
}));

const Select = ({
    $handleChange = () => null,
    $style = {},
    addSearchFilter = false,
    ariaLabel = '',
    defaultValue,
    errors = [],
    label,
    name,
    options,
    placeholder,
    required = false,
}) => {
    const [selectedOption, setSelectedOption] = useState(
        options.find(o => o.value?.toLowerCase() === defaultValue?.toLowerCase()) || {}
    );

    useEffect(() => {
        setSelectedOption(options.find(o => o.value?.toLowerCase() === defaultValue?.toLowerCase()));
    }, [defaultValue]);

    const [css] = useStyletron();
    const collapseRef = useRef(null);
    const filterInput = useRef(null);

    const [isOpen, setIsOpen] = useState(false);
    const [needle, setNeedle] = useState('');
    const [filter, setFilter] = useState('');

    if (!isOpen && needle.length) {
        setNeedle('');
    }

    if (isOpen && filterInput.current) {
        filterInput.current.focus();
    }

    useEffect(() => {
        // Delay input change so we don't do it while animating
        const timeOutId = setTimeout(() => setFilter(needle), 400);
        return () => clearTimeout(timeOutId);
    }, [needle]);

    useOnClickOutside(collapseRef, () => isOpen && setIsOpen(false));

    const handleSelect = (option, event) => {
        if (option) {
            setSelectedOption(option);
        }
        if (event) {
            $handleChange(event);
        }
    };
    useEffect(() => {
        setSelectedOption(options.find(o => o.value === defaultValue) || {});
    }, [JSON.stringify(options)]);

    const handleKeyDown = (e, option) => {
        // Space or Enter
        if (e.keyCode === 32 || e.keyCode === 13) {
            handleSelect(option);
            setIsOpen(false);
        }
        // Escape
        if (e.keyCode === 27) {
            setIsOpen(false);
        }
    };

    if (!options?.length) {
        return null;
    }

    useEffect(() => {
        if (options.length === 1) {
            const singleOption = options[0];
            handleSelect(singleOption);
        }
    }, [options]);

    return (
        <div className={css({ position: 'relative', marginTop: '12px', ...$style })}>
            <SelectMobile
                aria-label={ariaLabel}
                defaultValue={selectedOption.value}
                name={name}
                onChange={e => handleSelect(options.find(o => o.value === e.target.value), e)}
            >
                {options.map(option => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </SelectMobile>

            {/* We pass in the chosen value in to this hidden input, this is what's submitted to the form */}
            <input
                data-selectinput
                readOnly
                name={name}
                required={required}
                type="hidden"
                value={selectedOption.value}
            />

            {label && <Label htmlFor={name}>{`${required ? `${label} *` : label}`} </Label>}

            <Wrapper ref={collapseRef}>
                {options.length === 1 ? (
                    <ToggleButton type="button" tabIndex="-1" $errors={errors.length > 0} style={{ cursor: 'default' }}>
                        {selectedOption.icon && (
                            <ImageWrapper>
                                <Image $style={{ objectFit: 'cover' }} src={selectedOption.icon} />
                            </ImageWrapper>
                        )}
                        <TextSpan>{selectedOption.label}</TextSpan>
                    </ToggleButton>
                ) : (
                    <>
                        <ToggleButton
                            $errors={errors.length > 0}
                            tabIndex="-1"
                            type="button"
                            onClick={() => setIsOpen(!isOpen)}
                        >
                            {selectedOption.icon && (
                                <ImageWrapper>
                                    <Image $style={{ objectFit: 'cover' }} src={selectedOption.icon} />
                                </ImageWrapper>
                            )}
                            <StateIcon
                                $style={{ marginRight: '8px', marginBottom: '0.1em' }}
                                size="10px"
                                state={isOpen ? 'chevron-up' : 'chevron-down'}
                            />
                            <TextSpan
                                $style={{
                                    color: selectedOption.label ? 'inherit' : 'var(--color-text-subtle-account)',
                                }}
                            >
                                {selectedOption.label || placeholder || null}
                            </TextSpan>
                        </ToggleButton>
                        <Collapse height={isOpen ? 'auto' : 0}>
                            <CollapseInner>
                                {options.length > 10 && addSearchFilter && (
                                    <FilterInput
                                        placeholder={fm('Select an option or search...')}
                                        ref={filterInput}
                                        value={needle}
                                        onChange={e => setNeedle(e.target.value?.toLowerCase())}
                                        onFocus={() => setIsOpen(true)}
                                        onKeyDown={e => {
                                            e.key === 'Escape' && setIsOpen(false);
                                        }}
                                    />
                                )}
                                <ScrollableBox withScrollbar $style={{ maxHeight: '200px' }} as="ul">
                                    {options
                                        .filter(o =>
                                            filter.length ? o.label.toLowerCase().indexOf(filter) >= 0 : true
                                        )
                                        .map(option => (
                                            <OptionWrapper
                                                $hide={option.hide}
                                                $icon={option.icon}
                                                $selected={option.value === selectedOption.value}
                                                key={option.value}
                                            >
                                                <Option
                                                    tabIndex={isOpen ? '0' : '-1'}
                                                    type="button"
                                                    value={option.value}
                                                    onClick={e => {
                                                        handleSelect(option, e);
                                                        setIsOpen(false);
                                                    }}
                                                    onKeyDown={e => handleKeyDown(e, option)}
                                                >
                                                    {option.label}
                                                </Option>
                                            </OptionWrapper>
                                        ))}
                                    {options.filter(o =>
                                        filter.length ? o.label.toLowerCase().indexOf(filter) >= 0 : true
                                    ).length === 0 ? (
                                        <p className={css({ margin: '20px 0px' })}>{fm('No available options')}</p>
                                    ) : null}
                                </ScrollableBox>
                            </CollapseInner>
                        </Collapse>
                    </>
                )}
            </Wrapper>
            {errors.length > 0 && (
                <div
                    className={css({
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                    })}
                >
                    <InputErrors $style={{ position: 'absolute', bottom: '40px' }} errors={errors} />
                </div>
            )}
        </div>
    );
};

Select.propTypes = {
    $handleChange: PropTypes.func,
    $style: PropTypes.object,
    addSearchFilter: PropTypes.bool,
    ariaLabel: PropTypes.string,
    defaultValue: PropTypes.string,
    errors: PropTypes.array,
    label: PropTypes.string,
    name: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.string,
            label: PropTypes.string,
            icon: PropTypes.string,
        })
    ).isRequired,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
};

export default Select;
