import { ChangeEvent, useRef, useState } from 'react';
import { isValidEmail, isValidName, isValidStreetName, isValidZipCode } from '@activebrands/core-web/utils/validation';
import parsePhoneNumber, { CountryCode } from 'libphonenumber-js';

interface InputError {
    error: string;
    name: string;
    type?: string;
    value: string;
    min?: string;
    max?: string;
}
const _validateInput = (input: HTMLInputElement | HTMLTextAreaElement) => {
    const { name, type, value, required, validity } = input;
    const errors: InputError[] = [];
    const dataValidate = 'data-validate';
    const validateName = input.getAttribute(dataValidate) === 'name';
    const validateAddress = input.getAttribute(dataValidate) === 'address';
    const validateZipCode = input.getAttribute(dataValidate) === 'zipCode';

    for (const key in validity) {
        if (key !== 'valid' && validity[key]) {
            errors.push({ error: key, type, name, value });
        }
    }

    if (type === 'tel') {
        const dataType = input.getAttribute('data-type');

        if (input.value && input.value.length > 0 && dataType === 'phoneNumber') {
            const customerLocale = input.getAttribute('data-locale') as CountryCode;
            const parsedNumber = parsePhoneNumber(input.value, customerLocale);

            if (parsedNumber?.isValid()) {
                // We want to achieve two things here, both to display a formatted number, without the country code,
                // (as this is already present in our select box), and to store the full number in the data-value attribute
                // to pass this into our onSubmit callback and use as submission value
                input.value = parsedNumber.formatInternational().replace(`+${parsedNumber.countryCallingCode}`, '');
                input.setAttribute('data-value', parsedNumber.formatInternational());
            } else {
                errors.push({ error: 'invalidNumber', type, name, value });
            }
        }
    }

    if (type === 'email' && !validity.typeMismatch && value) {
        if (!isValidEmail(value)) {
            errors.push({ error: 'typeMismatch', type, name, value });
        }
    } else if (type === 'hidden' && required && !value) {
        errors.push({ error: 'valueMissing', type, name, value });
    } else if (type === 'range') {
        const { min, max } = input as HTMLInputElement;

        const rangeOverflow = max && parseInt(value, 10) > parseInt(max, 10);
        const rangeUnderflow = min && parseInt(value, 10) < parseInt(min, 10);

        if (rangeOverflow) {
            errors.push({ error: 'rangeOverflow', type, name, value, min, max });
        }
        if (rangeUnderflow) {
            errors.push({ error: 'rangeUnderflow', type, name, value, min, max });
        }
    } else if (validateZipCode && value) {
        const customerLocale = input.getAttribute('data-locale');
        if (!isValidZipCode(value, customerLocale)) {
            errors.push({ error: 'invalidZip', type, name, value });
        }
    } else if (validateName && value) {
        if (!isValidName(value)) {
            errors.push({ error: 'invalidName', type, name, value });
        }
    } else if (validateAddress && value) {
        if (!isValidStreetName(value)) {
            errors.push({ error: 'invalidAddress', type, name, value });
        }
    }

    return errors;
};

const serializeForm = (form: HTMLFormElement) => {
    const errors: Record<string, InputError[]> = {};
    const inputs: Record<string, any> = {};

    Array.from(form.elements).forEach((element: any) => {
        if (!element.name || element.disabled || ['reset', 'submit', 'button'].indexOf(element.type) > -1) {
            return;
        }

        const inputErrors = _validateInput(element as HTMLInputElement);

        if (inputErrors.length) {
            errors[element.name] = inputErrors;
        }

        if (['checkbox', 'radio'].indexOf(element.type) > -1) {
            inputs[element.name] = element.checked;
        } else if (element.type === 'tel') {
            inputs[element.name] = element.getAttribute('data-value') || element.value;
        } else {
            inputs[element.name] = element.value;
        }
    });

    return {
        errors,
        inputs,
    };
};

const useForm = (callback?: Function) => {
    const ref = useRef<HTMLFormElement | null>(null);
    const restored = useRef(false);
    const [errors, setErrors] = useState<Record<string, undefined | InputError[]>>({});

    const validateInput = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        event.persist();

        if (restored.current) {
            restored.current = false;

            return;
        }

        const inputErrors = _validateInput(event.target);

        const { name } = event.target;

        if (inputErrors.length) {
            setErrors(prevErrors => ({ ...prevErrors, [name]: inputErrors }));
        } else if (errors[name]) {
            setErrors(prevErrors => ({ ...prevErrors, [name]: undefined }));
        }
    };

    const onSubmit = (event: ChangeEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (restored.current) {
            restored.current = false;

            return;
        }

        // Set a different serialize func depending on the checkoutform or not.
        const form = serializeForm(event.target);

        if (Object.keys(form.errors).length) {
            setErrors(form.errors);
        } else if (typeof callback === 'function') {
            callback(form.inputs);
        }
    };

    const reset = () => {
        restored.current = true;

        if (ref.current) {
            ref.current.reset();
        }

        setErrors({});
    };

    return {
        errors,
        setErrors,
        reset,
        validateInput,
        props: {
            ref,
            noValidate: true,
            onSubmit,
        },
    };
};

export default useForm;
